import { useQuery } from "@tanstack/react-query";
import { getBlog } from "../../api/apiCalls";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ReactComponent as ZIVIL } from "../../assets/people-outline.svg"
import { ReactComponent as ARBEIT } from "../../assets/briefcase-outline.svg"
import { ReactComponent as MIETE } from "../../assets/business-outline.svg"
import { ReactComponent as VERKEHR } from "../../assets/car-sport-outline.svg"
import wallpaper from "../../assets/2149776012.jpg"

export default function BlogRoute() {
    const navigate = useNavigate()
    const { data: blogData } = useQuery({
        queryKey: ['blog'],
        queryFn: getBlog
    })  
    const trigger = (item) => {
        if (selectedPost === item) {
            setselectedPost()
        } else {
            navigate('/blog/' + item.id + "/" + item.attributes.Headline)
            setselectedPost(item)
        }
    } 
    const [selectedPost, setselectedPost] = useState()
    const setPostById = (id) => {
        let post = blogData.data.find(item => item.id === Number(id))
        setselectedPost(post)
    }

    const { id } = useParams()
    useEffect(() => {
        if (id && blogData) {
        setPostById(id)
        }
    }, [blogData, id])
    return (
        <>
            {
                selectedPost && selectedPost.attributes.seo &&
                <Helmet>
                    <title>{selectedPost ? selectedPost.attributes.seo.metaTitle : "Rechtsanwalt Leipzig | Kevin Wilke"}</title>
                    <meta name="og:title" content={selectedPost ? selectedPost.attributes.seo.metaTitle : "Rechtsanwalt Leipzig | Kevin Wilke"} />
                    <meta
                        name="description"
                        content={selectedPost ? selectedPost.attributes.seo.metaDescription : "Ihr Rechtsanwalt in Leipzig für Arbeitsrecht, Verkehrsrecht, Zivilrecht und Mietrecht! Kevin Wilke | Warschat Rechtsanwälte"}
                    />
                    <meta
                        name="og:description"
                        content={selectedPost ? selectedPost.attributes.seo.metaDescription : "Ihr Rechtsanwalt in Leipzig für Arbeitsrecht, Verkehrsrecht, Zivilrecht und Mietrecht! Kevin Wilke | Warschat Rechtsanwälte"}
                    />
                    <meta name="keywords" content={selectedPost ? selectedPost.attributes.seo.keywords :"Rechtsanwalt, Anwalt, Verteidiger, Leipzig, Mietrecht, Arbeitsrecht, Verkehrsrecht, Zivilrecht"} />
                    <link rel="canonical" href={"https://www.rawilke-leipzig.de/blog" + (selectedPost ? '/' + selectedPost.id : "")} />
                </Helmet> 
            }
            <section className="router-body flex flex-col items-center bg-black/30 gap-4 sm:gap-8 p-2 sm:p-8 flex-grow min-h-screen">
                {!blogData && <p className="py-12 uppercase font-bold underline text-xl">Noch keine Neuigkeiten vorhanden!</p>}
                {blogData && blogData?.data?.map((item, index) => (
                    <motion.div
                    key={index} className={`w-full max-w-screen-md rounded-lg shadow-lg h-fit bg-white flex ${item === selectedPost ? 'flex-col gap-8' : 'flex-row gap-8 items-center'} p-4`}>
                        { item !== selectedPost ?
                        (
                            item.attributes.Kategorie === "Zivilrecht" ? <ZIVIL className="w-24 h-24"/> :
                            item.attributes.Kategorie === "Arbeitsrecht" ? <ARBEIT className="w-24 h-24"/> :
                            item.attributes.Kategorie === "Mietrecht" ? <MIETE className="w-24 h-24"/> :
                             <VERKEHR className="w-24 h-24"/>
                        ) : 
                        <img src={wallpaper} alt="https://www.freepik.com/" className="object-cover object-bottom w-full h-48 rounded-md"/>
                        }
                        <div className={`flex flex-col gap-1 ${item === selectedPost ? 'w-full' : "w-4/5"} grow`}>
                            <h3 className="pt-1 text-xs text-start">Neues aus dem {item.attributes.Kategorie}...</h3>
                            <h1 className="text-lg font-bold uppercase underline text-start">{item.attributes.Headline}</h1>
                            {!(item === selectedPost) ?  
                            <p className={`line-clamp-3 text-start`}>
                                {item.attributes.Text[0].children[0].text}
                            </p>
                            :
                            <p className="text-start">

                            <BlocksRenderer className="text-start" content={item.attributes.Text} />
                            </p>
                            
                            }
                            <p className="hover:underline text-end self-end text-xs cursor-pointer w-fit" onClick={() => trigger(item)}>{item === selectedPost ?  "...weniger lesen" :  "mehr lesen..."}</p>
                        </div>
                    </motion.div>
                ))}
            </section>
        </>
    )
}
