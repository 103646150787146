import './App.css';
import Footer from './components/common/footer';
import {Navigate, Route, Routes} from 'react-router-dom'
import HomePageRoute from "./components/routes/homePageRoute";
import DatenschutzRoute from "./components/routes/datenschutzRoute";
import ImpressumRoute from "./components/routes/impressumRoute";
import ScrollToTop from "./components/routes/ScrollToTop";
import { getWelcome } from './api/apiCalls';
import { useQuery } from '@tanstack/react-query';
import { BACKEND } from './api/constants'
import Header from './components/common/header';
import BlogRoute from './components/routes/blogRoute';

function App() {
  const { data: welcomeData } = useQuery({
    queryKey: ['welcome'],
    queryFn: getWelcome
}) 
  const bgstyle = {backgroundImage: `url(${BACKEND.IMG + welcomeData?.data?.attributes?.Hintergrundbild?.data?.attributes?.url})`, backgroundSize: 'cover', backgroundAttachment: 'fixed', backgroundPosition: 'center',   backgroundRepeat: 'no-repeat', height: '100%'};

  return (
    <main className='min-h-screen overflow-hidden' style={bgstyle}>
      {/* TODO SEO npm*/}
      <div className=" flex-grow text-center bg-black/30  overflow-hidden pt-16" theme='maintheme'>
          <ScrollToTop>
              <Header />
              <Routes>
                <Route path='*' element={<Navigate to={'/'} />}/>
                <Route exact path={'/'} element={<HomePageRoute />} />
                <Route exact path={'/impressum'} element={<ImpressumRoute />} />
                <Route exact path={'/datenschutz'} element={<DatenschutzRoute />} />
                <Route exact path={'/blog'} element={<BlogRoute />} />
                <Route exact path={'/blog/:id/:title'} element={<BlogRoute />} />
              </Routes>
              <Footer />
          </ScrollToTop>
      </div>
    </main>
  );
}

export default App;
